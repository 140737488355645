








































































































































section {
  > header {
    width: 100%;
    height: 90px;
    background-color: rgb(0, 0, 0, 0.5);
  }

  main {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    > h2 {
      font-size: 48px;
      font-weight: normal;
      color: #333;
      line-height: 48px;
      margin: 80px 0;
    }

    .tab-list {
      padding: 20px 0;

      > ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        > li {
          display: flex;
          flex-direction: column;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 4px;
          flex-basis: 30%;
          margin-bottom: 15px;

          > img {
            max-width: 100%;
          }
          > span{
            font-size: 16px;
            color: #333;
            margin-top: 10px;
          }
        }
      }
    }

    > .pagination{
      float: right;
      margin: 50px 0;
    }
  }
}
// @media (max-width: 767px){
//   section {
//     main{
//       width: 100%;
//     }
//   }
// }
